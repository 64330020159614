*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100dvh !important;
}

.dialog-box {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 450px;
  height: 300px;
  align-items: center;
  border: none;
  border-radius: 40px;
  box-shadow: 0px 5px 32px 3px;
  transform-origin: center;
  transform: translate(50%, 50%);
}

.dialog-box::backdrop {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.235);
}

html::-webkit-scrollbar {
  display: none;
  width: 0;
}

::-webkit-scrollbar {
  display: none;
}

html {
  scrollbar-width: 0;
}
.css-oq5hqn-MuiInputBase-root.Mui-focused {
  border-color: #35d1f5;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 111;
  transition: all 1.5s ease-in !important;
  box-shadow: 0rem 0rem 0rem 0.125rem rgba(129, 227, 249, 1);
  outline: 0;
}


/* ----------------- Loader ---------------------- */

.loader {
  display: block;
  width: 246px;
  height: 166px;
}

.tree {
  fill: #2b3b86;
}

.circle-mask {
  transform-origin: 50% 100%;
  animation: scale 4s infinite ease-in;
}

@keyframes scale {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}